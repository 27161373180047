body {
  margin: 0;
  padding: 0;
  background-color: #f7fbff;
  color: #000;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif,
    Helvetica, sans-serif;
}

.footer-bottom {
  margin-top: 25px;
  padding-top: 55px;
  padding-bottom: 10px;
  background-image: linear-gradient(134deg, #3266c6 0%, #00b1e2 100%);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-bottom:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("./assets/sall_bg.jpg") center bottom no-repeat;
  top: 0px;
  left: 0px;
  display: none;
}
.footer-bottom .copyright {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #f3f8ff;
  letter-spacing: 1.7px;
  text-align: center;
  margin-top: -15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
}

@media (max-width: 991px) {
  .footer-bottom {
    padding-top: 40px;
  }
  .footer-bottom .copyright {
    margin: 0px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
  }
}
